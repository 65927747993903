/**
 * Amasty Shop By Brand compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import MoreFromBrandComponent from '../../component/MoreFromBrand';
import { Suspense } from 'react';

const addMoreFromBrandsToAdditionalSections = (args, callback, instance) => {
    const {
        areDetailsLoaded,
        activeProduct: { id }
    } = instance.props;

    if (!areDetailsLoaded) {
        return null;
    }
    return (
        <>
            { callback(...args) }
            <MoreFromBrandComponent productId={ id } />
        </>
    );
};

export default {
    'Route/ProductPage/Component': {
        'member-function': {
            renderAdditionalSections: addMoreFromBrandsToAdditionalSections
        }
    }
};
