import {
    ProductPageContainer as SourceProductPageContainer
} from "SourceRoute/ProductPage/ProductPage.container";
import {RootState} from "Util/Store/Store.type";
import {
    ProductPageContainerMapDispatchProps,
    ProductPageContainerMapStateProps
} from "Route/ProductPage/ProductPage.type";
import {Dispatch} from "redux";
import {changeNavigationState, goToPreviousNavigationState} from "Store/Navigation/Navigation.action";
import {NavigationType} from "Store/Navigation/Navigation.type";
import ProductDispatcher from "Store/Product/Product.dispatcher";
import {setBigOfflineNotice} from "Store/Offline/Offline.action";
import BreadcrumbsDispatcher from "Store/Breadcrumbs/Breadcrumbs.dispatcher";
import MetaDispatcher from "Store/Meta/Meta.dispatcher";
import {addRecentlyViewedProduct} from "Store/RecentlyViewedProducts/RecentlyViewedProducts.action";
import {connect} from "react-redux";

export const mapStateToProps = (state: RootState): ProductPageContainerMapStateProps => ({
    isOffline: state.OfflineReducer.isOffline,
    product: state.ProductReducer.product,
    metaTitle: state.MetaReducer.title,
    isMobile: state.ConfigReducer.device.isMobile,
    store: state.ConfigReducer.code,
    areReviewsEnabled: state.ConfigReducer.reviews_are_enabled,
});

export const mapDispatchToProps = (dispatch: Dispatch): ProductPageContainerMapDispatchProps => ({
    changeHeaderState: (state) => dispatch(changeNavigationState(NavigationType.TOP_NAVIGATION_TYPE, state)),
    changeNavigationState: (state) => dispatch(changeNavigationState(NavigationType.BOTTOM_NAVIGATION_TYPE, state)),
    requestProduct: (options) => ProductDispatcher.handleData(dispatch, options),
    setBigOfflineNotice: (isBig) => dispatch(setBigOfflineNotice(isBig)),
    updateBreadcrumbs: (breadcrumbs, prevCategoryId) => BreadcrumbsDispatcher.updateWithProduct(breadcrumbs, prevCategoryId, dispatch),
    updateMetaFromProduct: (product) => MetaDispatcher.updateWithProduct(product, dispatch),
    goToPreviousNavigationState: () => dispatch(goToPreviousNavigationState(NavigationType.TOP_NAVIGATION_TYPE)),
    addRecentlyViewedProduct: (product, store) => dispatch(addRecentlyViewedProduct(product, store)),
});

export class ProductPageContainer extends SourceProductPageContainer {
    updateMeta(): void {
        const { updateMetaFromProduct } = this.props;
        const {
            name = '',
            meta_title = '',
            meta_keyword = '',
            canonical_url,
            meta_description = '',
            //@ts-ignore
            meta_robots = ''
        } = this.getDataSource();

        updateMetaFromProduct({
            name,
            meta_title,
            meta_keyword,
            canonical_url,
            meta_description,
            //@ts-ignore
            meta_robots
        });
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductPageContainer);
