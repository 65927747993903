/**
 * Amasty Shop By Brand compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import ProductListQuery from 'Query/ProductList.query';
import { Field } from '@tilework/opus';


/** @namespace Scandiweb/AmastyShopbyBrand/Query/BrandsMoreFromBrand/Query/BrandsMoreFromBrandQuery */
export class BrandsMoreFromBrandQuery {
    getQuery({ productId }) {
        return new Field('amMoreFromThisBrand')
            .addArgument('productId', 'Int', productId)
            .addFieldList(this.getMoreFromThisBrandFields());
    }

    getMoreFromThisBrandFields() {
        return [
            'title',
            this.getItemsFields()
        ];
    }

    getItemsFields() {
        return new Field('items')
            .addFieldList(ProductListQuery._getProductInterfaceFields());
    }
}

export default new BrandsMoreFromBrandQuery();
