/**
 * Amasty Special Promotions Pro compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Children, cloneElement } from 'react';

import Banners from '../component/Banners';
import {
    AFTER_BANNER,
    PRODUCT_LABEL,
    TOP_BANNER
} from '../config/Banner.config';

export const addTopBanner = (args, callback) => (
    <>
        <Banners location={ TOP_BANNER } />
        { callback(...args) }
    </>
);

export const addAfterBanner = (args, callback) => (
    <>
        { callback(...args) }
        <Banners location={ AFTER_BANNER } />
    </>
);

export const addLabel = (args, callback) => {
    const elem = callback(...args);
    const { children } = elem.props;

    return cloneElement(
        elem,
        null,
        Children.map(children, (child, indx) => {
            if (indx === 0) {
                return (
                    <Banners location={ PRODUCT_LABEL } />
                );
            }

            return child;
        })
    );
};

export default {
    'Route/ProductPage/Component': {
        'member-function': {
            renderProductPageContent: addTopBanner
        }
    },
    'Component/ProductActions/Component': {
        'member-function': {
            renderDesktop: addAfterBanner,
            renderMobile: addAfterBanner
        }
    },
    'Component/ProductGallery/Component': {
        'member-function': {
            renderSlider: addLabel
        }
    }
};
